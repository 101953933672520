import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Footer from "./layout/FooterSection";
import Navbar from "./layout/Navbar";
import PreLoader from "./components/preloader/PreLoader";

function App() {
  const Landing = lazy(() => import("./pages/Landing"));
  const About = lazy(() => import("./pages/About"));
  const Help = lazy(() => import("./pages/Help"));
  const HowitWorks = lazy(() => import("./pages/HowitWorks"));

  return (
    <div className="">
      <PreLoader/>
      <Navbar />
      <Suspense fallback={"Loading..."}>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Help />} />
          <Route path="/howitworks" element={<HowitWorks />} />
        </Routes>
      </Suspense>
      <Footer />
    </div>
  );
}

export default App;
