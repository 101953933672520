import React from "react";

const ButtonPrimary = ({ text, fun }) => {
  return (
    <div>
      <button
        onClick={fun}
        className="flex px-10 py-3 bg-secondary rounded-md text-main text-base font-semibold hover:opacity-80 transition duration-300 ease-in-out"
      >
        {text}
      </button>
    </div>
  );
};

const ButtonSecondary = ({ text, fun }) => {
  return (
    <div>
      <button
        onClick={fun}
        className="flex px-10 py-3 bg-white border border-main rounded-md text-main text-base font-semibold hover:bg-main hover:bg-opacity-80 hover:text-white transition duration-300 ease-in-out"
      >
        {text}
      </button>
    </div>
  );
};

const DownloadButton = ({ text, logo, title, fun }) => {
  return (
    <div
      onClick={fun}
      className="cursor-pointer flex items-center justify-center gap-2 h-[60px]  w-[175px] bg-black rounded-xl text-white text-base font-semibold hover:opacity-80 transition duration-300 ease-in-out hover:scale-75"
    >
      <div className="">{logo}</div>
      <div className="flex flex-col justify-start items-start gap-0">
        <div className="text-[12px] opacity-90">{title}</div>
        <div className="text-[20px]">{text}</div>
      </div>
    </div>
  );
};

export { ButtonPrimary, ButtonSecondary, DownloadButton };
