import React, { useEffect } from "react";
import { preLoaderAnim } from "../animations";
import "./PreLoader.css";
import { motion } from "framer-motion";

const PreLoader = () => {
  useEffect(() => {
    preLoaderAnim();
  }, []);
  return (
    <div className="preloader">
      <div className="flex flex-col gap-4 items-center justify-center">
        <div className="flex flex-row gap-1 items-center justify-center">
          <motion.img src="Icon_logo.png" width="48" height="48" alt="logo" />
        </div>
        <div className="texts-container">
          <span>Ensure &nbsp;</span>
          <span>your Child's &nbsp;</span>
          <span>Safety &nbsp;</span>
          <span>Remotely. &nbsp;</span>
        </div>
      </div>
    </div>
  );
};

export default PreLoader;
