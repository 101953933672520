import React, { useEffect, useState } from "react";
import { ButtonPrimary } from "../components/buttons/Buttons";
import { BiHelpCircle } from "react-icons/bi";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { navlinks } from "../routes/routes";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Navbar = () => {
  const [showCloseIcon, setShowCloseIcon] = useState(false);
  const toggleIcons = () => {
    setShowCloseIcon((prevState) => !prevState);
  };
  
  const [active, setActive] = useState(0);
  function handleSetActive(index) {
    setActive(index);
    console.log(index);
    setShowCloseIcon(false);
  }

  const menuVariants = {
    open: {
      opacity: 1,
      y: 0,
      transition: {
        opacity: { duration: 0.3 },
        y: { type: "spring", stiffness: 300, damping: 30 },
      },
    },
    closed: {
      opacity: 0,
      y: "-100%",
      transition: {
        opacity: { duration: 0.3 },
        y: { type: "spring", stiffness: 300, damping: 30 },
      },
    },
  };

 useEffect(() => {
   
   window.scrollTo(0, 0);

   const handlePopstate = () => {
     
     const currentPath = window.location.pathname;
    
     const activeIndex = navlinks.findIndex(
       (link) => link.path === currentPath
     );
     
     setActive(activeIndex);
   };
  
   window.addEventListener("popstate", handlePopstate);

   return () => {
     window.removeEventListener("popstate", handlePopstate);
   };
 }, []);



  return (
    <header className="bg-main lg:px-20 lg:py-0 p-2 Neue fixed w-full z-10 ">
      <div className="container mx-auto flex  flex-row items-center justify-between  w-full ">
        <Link to="/" onClick={() => handleSetActive(0)}>
          <div className="">
            <img
              src="/assets/icons/Logo.png"
              alt="logo"
              className="object-contain"
            />
          </div>
        </Link>
        <div className="sm:block hidden">
          <div className="flex flex-row gap-32">
            <nav className=" flex  items-center text-base  flex-row justify-evenly gap-16 text-white">
              {navlinks.map((link, index) => (
                <div
                  key={index}
                  onClick={() => handleSetActive(index)}
                  className="cursor-pointer"
                >
                  <Link
                    className="cursor-pointer hover:text-yellow-300"
                    path={link.value}
                    to={link.path}
                  >
                    {link.title}
                  </Link>
                  {active === index && (
                    <div className="bg-yellow-400 rounded-full hidden sm:block w-1 h-1 my-1 sm:mx-auto " />
                  )}
                </div>
              ))}
            </nav>
            <div className="flex flex-row gap-10 items-center ">
              <div className="hidden lg:block">
                <ButtonPrimary text={"Download"} />
              </div>
              <Link to="/contact#{faq}">
                <BiHelpCircle
                  className="text-white cursor-pointer hidden sm:block"
                  size={25}
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="block sm:hidden">
          <div className="flex flex-row gap-3 items-center">
            <div onClick={toggleIcons}>
              {showCloseIcon ? (
                <AiOutlineClose
                  className="text-white cursor-pointer sm:hidden block"
                  size={25}
                />
              ) : (
                <AiOutlineMenu
                  className="text-white cursor-pointer sm:hidden block"
                  size={25}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {showCloseIcon && (
        <motion.div
          className="relative h-full w-full bg-main py-5 flex flex-col justify-center items-center gap-9 z-10"
          initial="closed"
          animate="open"
          exit="closed"
          variants={menuVariants}
        >
          <motion.nav
            className=" flex  items-center text-base flex-col justify-evenly gap-9 text-white"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            {navlinks.map((link, index) => (
              <motion.div
                key={index}
                onClick={() => handleSetActive(index)}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <Link
                  className="cursor-pointer"
                  path={link.value}
                  to={link.path}
                >
                  {link.title}
                </Link>
                {active === index && (
                  <div className="bg-white rounded-full hidden sm:block w-1 h-1 my-1 sm:mx-auto" />
                )}
              </motion.div>
            ))}
          </motion.nav>
          <div className="">
            <ButtonPrimary text={"Download"} />
          </div>
          <BiHelpCircle className="text-white cursor-pointer " size={25} />
        </motion.div>
      )}
    </header>
  );
};

export default Navbar;
