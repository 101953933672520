import React from "react";
import { ButtonPrimary } from "../components/buttons/Buttons";
import { BsInstagram, BsTwitter, BsYoutube} from 'react-icons/bs'
import {FaFacebookF, FaLinkedin} from 'react-icons/fa'

const Footer = () => {
  return (
    <div className="bg-main sm:px-20 px-4 pt-5 Neue">
      <div className="flex flex-row justify-between gap-2">
        <div className="w-10 h-10">
          <img src="/assets/icons/Icon_logo2.png" alt="logo" />
        </div>
        <div className="flex flex-row gap-4 w-full lg:max-w-[550px] pb-5">
          <input
            type="text"
            placeholder="Enter your email to get the latest news.."
            className="rounded-sm h-12 w-full lg:max-w-[382px] outline-none p-1 lg:px-4"
          />
          <ButtonPrimary text="Subscribe" />
        </div>
      </div>
      <div className=" bg-white h-0.5 w-full opacity-40" />
      <div className="py-5">
        <div className="lg:flex lg:flex-row justify-evenly gap-10 grid grid-cols-2">
          <div className="flex flex-col gap-5 text-white w-full ">
            <div className="text-lg font-semibold">Company</div>
            <div className="flex flex-col gap-4 text-sm font-medium">
              <span>About Us</span>
              <span>Support</span>
              <span>FAQ s</span>
            </div>
          </div>
          <div className="flex flex-col gap-5 text-white w-full ">
            <div className="text-lg font-semibold">Parent</div>
            <div className="flex flex-col gap-4 text-sm font-medium">
              <span>Locations</span>
              <span>Schools</span>
              <span>Parent Sign up</span>
              <span>Track Now</span>
            </div>
          </div>
          <div className="flex flex-col gap-5 text-white w-full ">
            <div className="text-lg font-semibold">Driver</div>
            <div className="flex flex-col gap-4 text-sm font-medium">
              <span>One Two</span>
              <span>Three Four</span>
              <span>Five Six</span>
              <span>Seven Eight</span>
            </div>
          </div>
          <div className="flex flex-col gap-5 text-white w-full ">
            <div className="text-lg font-semibold">Download App</div>
            <div className="flex flex-col gap-10 text-sm font-medium">
              <div className="flex flex-row gap-4">
                <div className="h-10 w-full max-w-[119.664px]">
                  <img
                    src="/assets/icons/button.svg"
                    alt="apple"
                    className="object-cover"
                  />
                </div>
                <div className="h-10 w-full max-w-[135px]">
                  <img
                    src="/assets/icons/button-1.svg"
                    alt="google"
                    className="object-cover"
                  />
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <span className="text-lg font-semibold">Join Us</span>
                <div className="flex flex-row gap-4">
                  <BsYoutube className="text-xl" />
                  <FaFacebookF className="text-xl" />
                  <BsTwitter className="text-xl" />
                  <BsInstagram className="text-xl" />
                  <FaLinkedin className="text-xl" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" bg-white h-0.5 w-full opacity-40" />
      <div className="py-5 flex lg:flex-row flex-col justify-between items-center gap-1 text-[#FFF]">
        <span className="text-xs font-normal">
          Copyright @ 2023. All rights reserved.
        </span>
        <span className="text-xs font-normal">
          Privacy policy | Terms of Service
        </span>
      </div>
    </div>
  );
};

export default Footer;
