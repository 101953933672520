export const navlinks = [
  {
    title: "Home",
    path: "/",
    value: 0,
  },

  {
    title: "How it Works",
    path: "/howitworks",
    value: 1,
  },
  {
    title: "About",
    path: "/about",
    value: 2,
  },
  {
    title: "Safety",
    path: "/howitworks#safety",
    value: 3,
  },
  {
    title: "Contact",
    path: "/contact",
    value: 4,
  },
];
